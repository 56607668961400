import React from 'react'
import Navbar from './Navbar'
import '../styles/global.css'
import logoFooter from "../assets/logoFooter.svg";

export default function Layout({fontBigger, fontSmaller, language, children }) {
  return (
    <div className="layout">
      <Navbar language={language} fontBigger={() => fontBigger()}  fontSmaller={() => fontSmaller()}/>
      <div className="content">
        { children }
      </div>
      <footer>
        <div style={{textAlign:"center", padding:"5%"}}>
          <img alt="Curecall logo" src={logoFooter}/>
          <h5>Powered by Curecall</h5>
        </div>
      </footer>
    </div>
  )
}