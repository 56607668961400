import React, {useEffect, useState } from "react"
import Layout from "../components/Layout"
import {graphql} from 'gatsby'
import RoundedButton from "../components/RoundedButton"
import '../styles/contents-details.css'
import SEO from "../components/SEO"

const ContentsDetails = ({ data }) => {
  const { html } = data.markdownRemark
  const { title, language } = data.markdownRemark.frontmatter

	const [fontSize, setFontSize] = useState(1);


  function fontBigger(){
    setFontSize(fontSize + 0.1)
  }

  function fontSmaller(){
    setFontSize(fontSize - 0.1)
  }

  return (
    <Layout language={language} fontBigger={() => fontBigger()}  fontSmaller={() => fontSmaller()}>
      <SEO 
        title={title} 
        lang={language}
        />
      <div className="content-details" style={{"--font-scale":fontSize}}>
        <h1>{title}</h1>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </div>
      <div style={{textAlign:"center"}}>
        <RoundedButton onClick={()=>{window.scrollTo(0, 0)}} label="Haut de page" color="#62B0E2"/>
      </div>
      <hr/>
    </Layout>
  )
}
 
export default ContentsDetails

export const query = graphql`
  query ContentsDetails($slug: String, $language: String) {
    markdownRemark(frontmatter: {slug: {eq: $slug}, language: {eq: $language}}) {
      html
      frontmatter {
        title
        language
      }
    }
  }
`