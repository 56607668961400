import React, {useEffect, useState } from "react"
import logoPointVision from "../assets/logoPointVision.svg";
import SquareButton from './SquareButton';
import '../styles/components/navbar.css'
import { navigate } from "gatsby"


export default function Navbar({fontBigger, fontSmaller, language}) {

	const [nextLanguage, setNextLanguage] = useState(null);

  useEffect(() => {
    if(language == "fr"){
      setNextLanguage("en");
    }else{
      setNextLanguage("fr");
    }
	}, [])

  function changelanguage(){
    var actualLocation = document.location.toString().split('/');
    navigate("/" + nextLanguage + "/" + actualLocation[4])
  }

  
  function handleBigger(){
    fontBigger();
  }

  function handleSmaller(){
    fontSmaller();
  }

  return (
    <nav>
        <div className="grid-logo-language">
          <div className="header">
            <img alt="Logo Point vision" src={logoPointVision}/>
            <h2>Votre centre médical d'ophtalmologie</h2>
          </div>
          <div className="language">
            <SquareButton onClick={() => changelanguage()} label={nextLanguage ? nextLanguage.toUpperCase() : ""} color="#D6AF60" height="9vw" width="9vw"/>
          </div>
        </div>
        <div className="grid-logo-resizers">
          <div/>
          <div className="resizers">
            <SquareButton onClick={() => handleSmaller()} label="-A" color="#62B0E2" height="7vw" width="7vw"/>
            <SquareButton onClick={() => handleBigger()} label="+A" color="#173961" height="9vw" width="9vw"/>
          </div>
        </div>
        <hr/>
    </nav>
  )
}